import React from 'react'
import Picture from '../Picture/Picture'
import Heading from '../Heading/Heading'
import Button from '../Button/Button'

import './Stage.scss'

const Stage = (props) => {
  const data = props.block.settings
  return (
    <div className="Stage">
      <Picture src={data.image.path} alt={data.image.meta.title} />
      <div className="content">
        <Heading tag="h1" text={data.headline} />
        {data.text && <p>{data.text}</p>}
        <Button url={data.link}>{data.linkText}</Button>
      </div>
    </div>
  )
}

export default Stage
