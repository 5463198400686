/* eslint-disable */
import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { OFFLINE } from './utils/apiData'
import './index.scss'

import Page from './components/Page/Page'

const App = () => {
  if (OFFLINE) {
    return (
      <div className="offline">
        <h3>
          Hier entsteht die Internet-Präsenz von <br />
          Alexandra Dumitrescu
        </h3>
      </div>
    )
  } else {
    return (
      <React.Fragment>
        <Router>
          <Switch>
            <Route path="/">
              <Page />
            </Route>
          </Switch>
        </Router>
      </React.Fragment>
    )
  }
}

export default App
