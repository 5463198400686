import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import Picture from '../Picture/Picture'
import Slider from 'react-slick'
import Button from '../Button/Button'
import './Products.scss'
import { APIURL, TOKEN } from '../../utils/apiData'

import { ShopSettings } from '../Page/Page'

const ProductDetail = (props) => {
  const [product, setProduct] = useState('')
  const settings = props.block.settings
  const thisSettings = useContext(ShopSettings)
  const active = thisSettings?.active
  let url = window.location.pathname.split('/')
  let history = useHistory()

  const sliderSettings = {
    dots: true,
    infinite: true,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: { slidesToShow: 1 },
      },
      {
        breakpoint: 992,
        settings: { arrows: true },
      },
      {
        breakpoint: 10000,
        settings: { arrows: true },
      },
    ],
  }

  useEffect(() => {
    const abortController = new AbortController()
    const getProducts = async () => {
      let collection = settings.name
      try {
        const result = await fetch(
          APIURL + '/api/collections/get/' + collection + '?token=' + TOKEN,
          {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              filter: { number: url[3] },
            }),
            signal: abortController.signal,
          },
        )
        const data = await result.json()
        setProduct(data.entries[0])
      } catch (error) {
        if (abortController.signal.aborted) {
          // cancelled
        } else throw error
      }
    }

    if (!product && active) {
      getProducts()
    }

    return () => abortController.abort()
  }, [product, url, settings.name, active])

  const addToCard = (item) => {
    let cart = []
    if (localStorage.getItem('cart')) {
      cart = JSON.parse(localStorage.getItem('cart'))
    }
    if (!cart.includes(item)) {
      cart.push(item)
    }

    localStorage.setItem('cart', JSON.stringify(cart))
    localStorage.setItem(item, JSON.stringify(product))
    props.cart()
    history.push('/warenkorb')
  }

  const DetailView = () => {
    return (
      <div>
        <div className="topWrapper">
          <div className="imgGallery">
            {product.pictures && (
              <Slider {...sliderSettings}>
                {product.pictures.map((el, index) => (
                  <div key={index} className="slide">
                    <div className="Image">
                      <Picture
                        className={`tns-lazy-img`}
                        src={el.path}
                        alt=""
                      />
                    </div>
                  </div>
                ))}
              </Slider>
            )}
          </div>
          <div className="name">
            <h1>{product.name}</h1>
            <div className="price">
              {parseFloat(product.price).toFixed(2)} €
            </div>
            <div className="buttonCard">
              {!product.verkauft && (
                <button
                  className="invert"
                  onClick={() => addToCard(product.number)}
                >
                  In den Warenkorb
                </button>
              )}
              {product.verkauft && (
                <div>Dieses Produkt wurde bereits verkauft</div>
              )}
            </div>

            <div className="rechtliches">
              Endpreis zzgl. Versandkosten, keine Ausweisung der Mehrwertsteuer
              gemäß § 19 UStG
            </div>
          </div>
        </div>
        <div className="bottomWrapper">
          <div className="description">
            <h2>Produktbeschreibung</h2>
            <div
              dangerouslySetInnerHTML={{ __html: product.description }}
            ></div>
          </div>
          <div className="details">
            <div className="info">
              <span className="bold">Artikel</span>
              {product.number}
            </div>
            {product.color && (
              <div className="info">
                <span className="bold">Farbe</span>
                {product.color}
              </div>
            )}
            {product.origin && (
              <div className="info">
                <span className="bold">Herkunft</span>
                {product.origin}
              </div>
            )}
            {product.stones && (
              <div className="info">
                <span className="bold">Besatzstein</span>
                {product.stones}
              </div>
            )}
            {product.metal && (
              <div className="info">
                <span className="bold">Metall</span>
                {product.metal}
              </div>
            )}
            {product.weight && (
              <div className="info">
                <span className="bold">Gewicht (in ct)</span>
                {product.weight}
              </div>
            )}
            <div className="info">
              <span className="bold">Maße</span>
              <span>Länge: {product.laenge}</span>
              <span>Breite: {product.breite}</span>
              <span>Höhe: {product.hoehe}</span>
            </div>
          </div>
        </div>
        <div className="buttonCard sticky">
          {!product.verkauft && (
            <button
              className="invert"
              onClick={() => addToCard(product.number)}
            >
              In den Warenkorb
            </button>
          )}
          {product.verkauft && <div>Dieses Produkt wurde bereits verkauft</div>}
        </div>
        <Button url="/produkte" type="invert full">
          Zur Produkübersicht
        </Button>
      </div>
    )
  }

  return (
    <div className={'ProductDetail ' + settings.class} id={settings.id}>
      {active && <DetailView />}
      {!active && (
        <div className="center">Der Shop ist zur Zeit nicht verfügbar.</div>
      )}
    </div>
  )
}

export default ProductDetail
