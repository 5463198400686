import React from 'react'
import Picture from '../Picture/Picture'
import './Image.scss'
import { DOMAIN } from '../../utils/apiData'

const Image = (props) => {
  const settings = props.block.settings

  if (props.block.settings.image) {
    let path = props.block.settings.image.path
    console.log(path)

    if (settings.class === 'full') {
      return (
        <div className={'Image ' + settings.class} id={settings.id}>
          <Picture src={path} alt={settings?.image?.meta?.title} />
        </div>
      )
    } else {
      return (
        <>
          <img
            src={DOMAIN + path}
            alt={settings?.image?.meta?.title}
            className={`Image ${settings.class}`}
            id={settings.id}
          />
        </>
      )
    }
  } else {
    return (
      <div className={'Image ' + settings.class} id={settings.id}>
        No Image set!
      </div>
    )
  }
}

export default Image
