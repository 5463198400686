import React from 'react'

const Html = (props) => {
  const settings = props.block.settings
  return (
    <div
      className={'Html ' + settings.class}
      id={settings.id}
      dangerouslySetInnerHTML={{ __html: settings.html }}
    ></div>
  )
}

export default Html
