import React, { useState, useEffect } from 'react'
import PageElements from '../PageElements/PageElements'
import ShoppingCartPage from '../ShoppingCart/ShoppingCartPage'
import Checkout from '../ShoppingCart/CheckOut'
import BestellungDanke from '../ShoppingCart/BestellungDanke'
import { APIURL, TOKEN } from '../../utils/apiData'

import Spinner from '../../statics/img/spinner.gif'

const PageContents = (props) => {
  const [state, setState] = useState({
    pagedata: props.block,
    page: '',
  })

  useEffect(() => {
    let page = props.page
    let pathArray = window.location.pathname.split('/')

    if (page === '/' || page === '') {
      page = 'startseite'
    } else {
      page = pathArray[1]
      setState((prev) => ({
        ...prev,
        page: page,
      }))
    }
    setState((prev) => ({
      ...prev,
      pagedata: props.block,
    }))
  }, [props])

  const getPageData = (page, pagedata) => {
    if (page === '/' || page === '') {
      page = 'startseite'
    }
    if (page) {
      const thisPage = pagedata.map((item) => {
        if (item.Titel_slug === page) {
          return item
        } else {
          return null
        }
      })
      const filtered = thisPage.filter((el) => {
        return el != null
      })
      return filtered
    }
  }

  const pagedata = state?.pagedata
  const entries = getPageData(state.page, pagedata)

  const Sections = () => {
    if (entries[0]) {
      return entries[0].Layout.map((item, index) => (
        <PageElements
          className={item.component}
          key={index}
          block={item}
          cart={props.cart}
        />
      ))
    } else {
      switch (state.page) {
        case 'warenkorb':
          return <ShoppingCartPage cart={props.cart} />

        case 'checkout':
          return <Checkout cart={props.cart} />

        case 'bestellung-danke':
          return <BestellungDanke cart={props.cart} />

        default:
          return (
            <div className="e404">
              <img src={Spinner} alt="Seite wird geladen..." />
            </div>
          )
      }
    }
  }

  return (
    <main>
      {entries && <Sections api={APIURL} token={TOKEN} key={state.page} />}
    </main>
  )
}

export default PageContents
