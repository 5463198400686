import React from 'react'
import './Heading.scss'

const Heading = (props) => {
  const settings = props?.block?.settings
    ? props.block.settings
    : {
        tag: props.tag,
        text: props.text,
        class: '',
        id: '',
      }
  return (
    <>
      {settings.tag === 'h1' ? (
        <h1 className={'Heading ' + settings.class} id={settings.id}>
          {settings.text}
        </h1>
      ) : null}
      {settings.tag === 'h2' ? (
        <h2 className={'Heading ' + settings.class} id={settings.id}>
          {settings.text}
        </h2>
      ) : null}
      {settings.tag === 'h3' ? (
        <h3 className={'Heading ' + settings.class} id={settings.id}>
          {settings.text}
        </h3>
      ) : null}
      {settings.tag === 'h4' ? (
        <h4 className={'Heading ' + settings.class} id={settings.id}>
          {settings.text}
        </h4>
      ) : null}
      {settings.tag === 'h5' ? (
        <h5 className={'Heading ' + settings.class} id={settings.id}>
          {settings.text}
        </h5>
      ) : null}
      {settings.tag === 'h6' ? (
        <h6 className={'Heading ' + settings.class} id={settings.id}>
          {settings.text}
        </h6>
      ) : null}
    </>
  )
}

export default Heading
