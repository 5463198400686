import React from 'react'
import PageElements from '../PageElements/PageElements'
import { APIURL, TOKEN } from '../../utils/apiData'
import { useHistory } from 'react-router-dom'

import './Form.scss'

const Form = (props) => {
  const settings = props.block.settings
  const history = useHistory()

  const submit = (event) => {
    event.preventDefault()
    let myForm = document.getElementById(settings.id)
    let formData = new FormData(myForm)
    console.log(history)

    let object = {}
    formData.forEach((value, key) => {
      // Reflect.has in favor of: object.hasOwnProperty(key)
      if (!Reflect.has(object, key)) {
        object[key] = value
        return
      }
      if (!Array.isArray(object[key])) {
        object[key] = [object[key]]
      }
      object[key].push(value)
    })

    if (settings.type === 'Formular') {
      fetch(
        APIURL + '/api/forms/submit/' + settings.endpoint + '?token=' + TOKEN,
        {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            form: object,
          }),
        },
      )
        .then((entry) => entry.json())
        .then(() => {
          //console.log(entry);
          if (settings.forward) {
            history.push(settings.forward)
          }
        })
    }

    if (settings.type === 'Datenbank') {
      fetch(
        APIURL +
          '/api/collections/save/' +
          settings.endpoint +
          '?token=' +
          TOKEN,
        {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            data: object,
          }),
        },
      )
        .then((res) => res.json())
        .then(() => {
          //console.log(entry);
          if (settings.forward) {
            history.push(settings.forward)
          }
        })
    }
  }

  if (props.block.children.length > 0) {
    const columnsArr = props.block.children

    if (settings.database) {
      let next = document.form.length
      if (settings.id) {
        document.form[next] = {
          id: settings.id,
          database: settings.database,
        }
      }
    }

    const Element = () => {
      if (columnsArr.length > 0) {
        return columnsArr.map((item, id) => (
          <PageElements block={item} key={id} />
        ))
      }
    }
    return (
      <form
        className={'Form ' + settings.class}
        id={settings.id}
        name={settings.name}
        onSubmit={submit}
      >
        <Element />
        <button className="Button">Absenden</button>
        <p>* Pflichtfeld</p>
      </form>
    )
  } else {
    return (
      <div className={'Form ' + settings.class} id={settings.id}>
        No Form set!
      </div>
    )
  }
}

export default Form
