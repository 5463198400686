import React from 'react'
import { APIURL, IMGTOKEN } from '../../utils/apiData'

const Picture = (props) => {
  const source = props.src ? props.src : null
  const alt = props.alt
  const apiImg =
    APIURL +
    '/api/cockpit/image?token=' +
    IMGTOKEN +
    '&src=' +
    source +
    '&o=true&q=80'
  const imgWidth = [
    { img: '800', media: '576', minMax: 'max' },
    { img: '1000', media: '768', minMax: 'max' },
    { img: '1200', media: '992', minMax: 'max' },
    { img: '2048', media: '993', minMax: 'min' },
  ]

  let src

  if (source?.indexOf('/') === 0) {
    src = apiImg + '&w=2048'
  } else {
    src = source
  }

  const SourceSet = () => {
    if (source.indexOf('/') === 0) {
      return imgWidth.map((item, key) => (
        <source
          srcSet={apiImg + '&w=' + item.img}
          media={'(' + item.minMax + '-width: ' + item.media + 'px'}
          key={key}
        />
      ))
    } else {
      return <source srcSet={source} media="(min-width: 0px"></source>
    }
  }
  if (source) {
    return (
      <picture className="Picture">
        <SourceSet />
        <img alt={alt} className={props.class} src={src} />
      </picture>
    )
  } else {
    return <div></div>
  }
}

export default Picture
