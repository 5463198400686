import React from 'react'
import Components from '../components'

const renderComponent = (block, id, api, token, cart) => {
  let type = (string) => {
    return string?.charAt(0).toUpperCase() + string?.slice(1)
  }

  if (typeof Components[type(block.component)] !== 'undefined') {
    return React.createElement(Components[type(block.component)], {
      key: id,
      block: block,
      api: api,
      token: token,
      cart: cart,
    })
  }
  return React.createElement(
    () => (
      <>
        <br />
        <pre>
          The component <b>{type(block.component)}</b> has not been created yet.
        </pre>
        <br />
      </>
    ),
    { key: block.id },
  )
}

export default renderComponent
