import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import Picture from '../Picture/Picture'
import './Products.scss'

import { ShopSettings } from '../Page/Page'

import { APIURL, TOKEN } from '../../utils/apiData'

const Products = (props) => {
  const [products, setProducts] = useState('')
  //const [active, setActive] = useState(false);
  const settings = props.block.settings
  const thisSettings = useContext(ShopSettings)
  const active = thisSettings?.active
  let history = useHistory()

  useEffect(() => {
    const abortController = new AbortController()
    const getProducts = async () => {
      let collection = settings.collection
      localStorage.setItem('products', collection)

      try {
        const result = await fetch(
          APIURL + '/api/collections/get/' + collection + '?token=' + TOKEN,
          {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({}),
            signal: abortController.signal,
          },
        )
        const data = await result.json()
        setProducts(data)
      } catch (error) {
        if (abortController.signal.aborted) {
          // cancelled
        } else throw error
      }
    }

    if (active) {
      getProducts()
    }
    return () => abortController.abort()
  }, [active, settings.collection])

  const ProductOverview = () => {
    if (active) {
      return products.entries.map((item, key) => productRender(item, key))
    } else {
      return null
    }
  }

  const productRender = (item, key) => {
    if (!item.verkauft) {
      return (
        <div
          role="button"
          tabIndex="0"
          onKeyDown={() =>
            history.push(
              '/produkt-detail/' + item.name_slug + '/' + item.number,
            )
          }
          className="item"
          key={key}
          onClick={() =>
            history.push(
              '/produkt-detail/' + item.name_slug + '/' + item.number,
            )
          }
        >
          <div className="image">
            <Picture src={item.pictures[0].path} alt="Produktbild" />
          </div>
          <div className="name">{item.name}</div>
          <div className="price">{parseFloat(item.price).toFixed(2)} € *</div>
        </div>
      )
    } else {
      return null
    }
  }

  return (
    <div className={'Products ' + settings.class} id={settings.id}>
      {products && <ProductOverview key={products} />}
      {!active && <div>Der Shop ist zur Zeit nicht verfügbar.</div>}
      <div className="rechtliches">
        * Endpreis zzgl. Versandkosten, keine Ausweisung der Mehrwertsteuer
        gemäß § 19 UStG
      </div>
    </div>
  )
}

export default Products
