import React, { useState, useEffect, createContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Navi from '../Navi/Navi'
import ShoppingCart from '../ShoppingCart/ShoppingCart'
import PageContents from '../PageContents/PageContents'
import { APIURL, TOKEN, PAGE, SHOP } from '../../utils/apiData'
//import "./App.scss";

export const ShopSettings = createContext()

const Page = () => {
  document.backend = APIURL
  let location = useLocation()
  document.form = []

  const [state, setState] = useState({
    pages: [],
    pagedata: [],
    cart: 0,
  })

  useEffect(() => {
    const abortController = new AbortController()
    const getSettings = async () => {
      try {
        const result = await fetch(
          APIURL + '/api/singletons/get/shopSettings?token=' + TOKEN,
          { signal: abortController.signal },
        )
        const data = await result.json()
        setState((prev) => ({
          ...prev,
          shopSettings: data,
        }))
      } catch (error) {
        if (abortController.signal.aborted) {
          // cancelled
        } else throw error
      }
    }

    if (SHOP) {
      if (!state.shopSettings) {
        getSettings()
      }
    }

    return () => abortController.abort()
  }, [state.shopSettings])

  useEffect(() => {
    const abortController = new AbortController()

    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera

    const getPageInfo = async () => {
      try {
        const page = await fetch(
          APIURL + '/api/collections/get/' + PAGE + '?token=' + TOKEN,
          {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({}),
            signal: abortController.signal,
          },
        )
        const pagedata = await page.json()

        const footer = await fetch(
          APIURL + '/api/singletons/get/footer?token=' + TOKEN,
          {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({}),
            signal: abortController.signal,
          },
        )
        const footerdata = await footer.json()

        setState((prev) => ({
          ...prev,
          pagedata: pagedata.entries,
          footerdata: footerdata,
        }))
      } catch (error) {
        if (abortController.signal.aborted) {
          // cancelled
        } else throw error
      }
    }
    if (state.pagedata.length === 0) {
      getPageInfo()
    }
    updateCart()
    return () => abortController.abort()
  }, [location, state.pagedata.length])

  const updateCart = () => {
    let number = []
    if (localStorage.getItem('cart')) {
      number = JSON.parse(localStorage.getItem('cart'))
    }
    setState((prev) => ({
      ...prev,
      cart: number.length,
    }))
  }

  const Footer = () => {
    const Links = () => {
      const getLinkName = (link) => {
        return state.pagedata?.map((item) => {
          if (item.Titel_slug === link) {
            return item.Titel
          } else {
            return null
          }
        })
      }
      return state.footerdata?.links.map((link, id) => (
        <li key={id}>
          <Link to={link.display}>{getLinkName(link.display)}</Link>
        </li>
      ))
    }
    return (
      <footer>
        {state.footerdata?.address && (
          <div
            className="adresse"
            dangerouslySetInnerHTML={{ __html: state.footerdata?.address }}
          ></div>
        )}
        {state.footerdata?.links && (
          <ul className="footerNavi">
            <Links />
          </ul>
        )}
      </footer>
    )
  }

  return (
    <ShopSettings.Provider value={state.shopSettings}>
      <div className="App">
        <header className="App-header">
          <Navi block={state.pagedata} />
          {SHOP && <ShoppingCart number={state.cart} cart={updateCart} />}
        </header>
        <PageContents
          block={state.pagedata}
          api={APIURL}
          token={TOKEN}
          page={location.pathname}
          cart={updateCart}
          key={location.pathname}
        />
        <Footer />
      </div>
    </ShopSettings.Provider>
  )
}

export default Page
