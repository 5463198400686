import React from 'react'

const Input_text = (props) => {
  const settings = props.block.settings
  let type

  const checkMe = (e) => {
    let elm = e.target
    if (elm.checkValidity()) {
      console.log('valid')
    } else {
      console.log('INvalid')
    }
  }

  switch (settings.type) {
    case 'Text Einzeilig':
      type = 'text'
      return (
        <div className={'formfield ' + settings.name}>
          <label htmlFor={settings.name}>
            <span className="label">{`${settings.label} ${
              settings.required ? '*' : ''
            }`}</span>
          </label>
          {settings.required ? (
            <input
              type={type}
              name={settings.name}
              placeholder={`${
                settings.placeholder ? settings.label + '*' : ''
              } `}
              required
              onChange={checkMe}
            />
          ) : (
            <input
              type={type}
              name={settings.name}
              placeholder={`${settings.placeholder ? settings.label : ''} `}
            />
          )}
        </div>
      )

    case 'E-Mail':
      type = 'email'
      return (
        <div className={'formfield ' + settings.name}>
          <label htmlFor={settings.name}>
            <span className="label">{`${settings.label} ${
              settings.required ? '*' : ''
            }`}</span>
          </label>
          {settings.required ? (
            <input
              type={type}
              name={settings.name}
              placeholder={`${
                settings.placeholder ? settings.label + '*' : ''
              } `}
              required
              onChange={checkMe}
            />
          ) : (
            <input
              type={type}
              name={settings.name}
              placeholder={`${settings.placeholder ? settings.label : ''} `}
            />
          )}
        </div>
      )

    case 'Telefon':
      type = 'tel'
      return (
        <div className={'formfield ' + settings.name}>
          <label htmlFor={settings.name}>
            <span className="label">{`${settings.label} ${
              settings.required ? '*' : ''
            }`}</span>
          </label>
          {settings.required ? (
            <input
              type={type}
              name={settings.name}
              placeholder={`${
                settings.placeholder ? settings.label + '*' : ''
              } `}
              pattern="^[0-9-+\s()]*$"
              required
              onChange={checkMe}
            />
          ) : (
            <input
              type={type}
              name={settings.name}
              placeholder={`${settings.placeholder ? settings.label : ''} `}
              pattern="^[0-9-+\s()]*$"
            />
          )}
        </div>
      )

    case 'Text Mehrzeilig':
      type = 'textarea'
      return (
        <div className={'formfield ' + settings.name}>
          <label htmlFor={settings.name}>
            <span className="label">{`${settings.label} ${
              settings.required ? '*' : ''
            }`}</span>
          </label>
          {settings.required ? (
            <textarea
              name={settings.name}
              placeholder={`${
                settings.placeholder ? settings.label + '*' : ''
              } `}
              required
              onChange={checkMe}
            ></textarea>
          ) : (
            <textarea
              name={settings.name}
              placeholder={`${settings.placeholder ? settings.label : ''} `}
            ></textarea>
          )}
        </div>
      )

    default:
      type = 'text'
      return (
        <div className={'formfield ' + settings.name}>
          <label htmlFor={settings.name}>
            <span className="label">{settings.label}</span>
          </label>
          {settings.required ? (
            <input
              type={type}
              name={settings.name}
              placeholder={`${
                settings.placeholder ? settings.label + '*' : ''
              } `}
              required
              onChange={checkMe}
            />
          ) : (
            <input
              type={type}
              name={settings.name}
              placeholder={`${settings.placeholder ? settings.label : ''} `}
            />
          )}
        </div>
      )
  }
}

export default Input_text
