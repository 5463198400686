import React from 'react'
import Button from '../Button/Button'

import './BestellungDanke.scss'

const BestellungDanke = () => {
  const state = JSON.parse(localStorage.getItem('order'))

  return (
    <div className="Danke">
      <h3>Vielen Dank</h3>
      <p>
        Wir haben Ihre Bestellung erhalten und werden diese schnellstmöglich
        bearbeiten.
      </p>
      <div className="order">
        <div className="overview">
          <h5>Bestellübersicht</h5>
          <ul>
            <li>
              <span className="head">Bestellnummer:</span>{' '}
              {state.order && state.order.order}
            </li>
            <li>
              <span className="head">Produkte:</span>
              {state.cart &&
                state.cart.map((item, key) => (
                  <span key={key} className="produkt">
                    {item}
                  </span>
                ))}
            </li>
            <li>
              <span className="head">Zahlungsmethode:</span>{' '}
              {state.order && state.order.payment}
            </li>
            <li>
              <span className="head">Lieferadresse:</span>{' '}
              {state.order && state.order.address}
            </li>
            <li>
              <span className="head">E-Mail:</span>{' '}
              {state.order && state.order.email}
            </li>
            <li>
              <span className="head">Gesamtbetrag:</span>{' '}
              {state.order && state.order.costs} €
            </li>
          </ul>
        </div>
        <div className="payment">
          {state.payment && state.payment === 'Vorkasse' && (
            <h5>Zahlungsdetails</h5>
          )}
          {state.payment && state.payment === 'Vorkasse' && (
            <div>
              <p>Sie möchten per Vorkasse zahlen.</p>
              <p>
                Bitte überweisen Sie den Gesamtbetrag in höhe von{' '}
                <strong>{state.price && state.price} €</strong> auf folgendes
                Konto:
              </p>
              <ul>
                <li>
                  <span className="head">Kontoinhaber:</span>{' '}
                  {state.settings && state.settings.kontoName}
                </li>
                <li>
                  <span className="head">IBAN:</span>{' '}
                  {state.settings && state.settings.iban}
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <Button url="/" type="invert">
        Zur Startseite
      </Button>
    </div>
  )
}

export default BestellungDanke
