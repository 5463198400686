import React from 'react'
import PageElements from '../PageElements/PageElements'
import './Grid.scss'

const Grid = (props) => {
  const settings = props.block.settings

  if (props.block.columns.length > 0) {
    const columnsArr = props.block.columns
    console.log(columnsArr)
    const Columns = () => {
      if (columnsArr.length > 1) {
        return columnsArr.map((item, id) => (
          <div className={`col ${item.settings.class}`} key={id}>
            <PageElements block={item.children} />
          </div>
        ))
      }
    }
    return (
      <section className={'Grid ' + settings.class} id={settings.id}>
        <Columns />
      </section>
    )
  } else {
    return (
      <div className={'Grid ' + settings.class} id={settings.id}>
        No Grid set!
      </div>
    )
  }
}

export default Grid
